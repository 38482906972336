.remoteDetail {
  margin-top: 20px;
  font-size: 14px;

  .remoteDetailBox {
    padding: 8px 0;
    line-height: 20px;
    color: #121212;
  }

  .viewMoreContent {
    position: relative;
    max-height: 78px;
    margin-bottom: 10px;
    overflow: hidden;
    word-break: break-word;
  }

  .viewMore {
    display: inline-flex;
    align-items: center;
    margin: 0 0 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #646566;
    cursor: pointer;
  }

  h4 {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #3b3c3c;
  }

  .label {
    font-weight: 400;
  }

  .viewMore svg {
    margin-left: 4px;
  }

  .close svg {
    transform: rotate(-180deg);
  }

  .openCountryWrap {
    border-bottom: 1px solid #f4f5f6;
  }

  .openCountryTip {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 8px 12px;
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    background: #fff0e9;
    border-radius: 8px;

    .tipIcon {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }
}